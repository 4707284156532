import React from "react";
import RecipeBanner from "./WhyIndivit/RecipeBanner";
import { BoxCard, RecipeCard, SkeltonCard } from "../components/Cards";
import SustainableSection from "../components/section/SustainableSection";
import VisionRecipeSection from "../components/section/VisionRecipeSection";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import rightIcon from "../assets/img/left.png";
import leftIcon from "../assets/img/right.png";
import { getLimitedSmoothies, getSmoothies } from "../services/Smoothie";
import { getLimitedBoxes } from "../services/SmoothieBox";
import { useQuery } from "@tanstack/react-query";
import HomeCarousel from "../components/HomeCarousel";
import { Helmet } from "react-helmet";
import { getWebSettings } from "../services/Settings";
import { useSelector } from "react-redux";
import Testimonials from "../components/section/Testimonials";
import { Link, useLocation } from "react-router-dom";
import WhyIndivitSection from "../components/Home/WhyIndivitSection";
import ProductCategory from "../components/Home/ProductCategory";
import HowItWorks from "../components/Home/HowItWorks";
import FAQSection from "../components/Home/FAQsSection";
import arrowTheme from "../assets/icon/arrow-right-theme.png";
import { getHomePage } from "../services/Home";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 700 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 700, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className="carousel-button-group position-absolute  w-100 ">
      <button
        className=" btn position-absolute start-0 px-0 shadow-none"
        onClick={() => previous()}
      >
        <img
          height="114px"
          width="62px"
          className="img-fluid "
          src={leftIcon}
        />
      </button>
      <button
        className="btn position-absolute end-0 px-0 shadow-none"
        onClick={() => next()}
      >
        <img
          height="114px"
          width="62px"
          className="img-fluid "
          src={rightIcon}
        />
      </button>
    </div>
  );
};
export default function Home() {
  const { pathname } = useLocation();
  const pageTitle = useSelector((state) => state.settings?.name);

  // const {
  //   isLoading: smoothieLoading,
  //   error: smoothieError,
  //   data: smoothieData,
  // } = useQuery({
  //   queryKey: ["limitedAdminSmoothies"],
  //   queryFn: getLimitedSmoothies,
  //   // get Admin Smoothies Limit
  // });
  // const {
  //   isLoading: boxLoading,
  //   error: boxError,
  //   data: boxData,
  // } = useQuery({
  //   queryKey: ["limitedboxListing"],
  //   queryFn: getLimitedBoxes,
  // });
  const {
    isLoading: homeLoading,
    error: homeError,
    data: homeData,
  } = useQuery({
    queryKey: ["homePage"],
    queryFn: getHomePage,
  });

  console.log("Page Title ", pageTitle);
  let sliderData = homeData?.data?.data?.sliders || [];
  let whyIndivitData = homeData?.data?.data?.whyIndivitSection || [];
  let boxCategories = homeData?.data?.data?.categories || [];
  let testimonialsData = homeData?.data?.data?.testimonials || [];
  let howItData = homeData?.data?.data?.howItsWorkSection || [];
  let faqsData = homeData?.data?.data?.faqs || {};
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
      </Helmet>
      {pageTitle && (
        <Helmet>{pageTitle && <title> {`${pageTitle}`} </title>}</Helmet>
      )}
      {/* {(smoothieLoading || boxLoading) && <Loader />} */}
      {<HomeCarousel data={sliderData} isLoading={homeLoading} />}

      {/* {JSON.stringify(homeData?.data?.data)} */}
      {/* <ReadyBoxesSection /> */}

      {!homeLoading && whyIndivitData?.length !== 0 && (
        <WhyIndivitSection data={whyIndivitData} />
      )}

      {/* New Product Category Section  */}
      <ProductCategory data={boxCategories} />
      {/* Ends Product Category Section Ends */}
      {/* Testimonials Start Here */}
      <div>
        <Testimonials data={testimonialsData} isLoading={homeLoading} />
      </div>

      <div className="tw-bg-[#DCE9C7]">
        <HowItWorks data={howItData} />
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 200">
          <path
            fill="#F8F9FA"
            fillOpacity="1"
            d="M0,128L60,106.7C120,85,240,43,360,58.7C480,75,600,149,720,154.7C840,160,960,96,1080,90.7C1200,85,1320,139,1380,165.3L1440,192L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
          ></path>
        </svg>
      </div>

      {/* FAQ Section */}
      <div className=" tw-py-14">
        {faqsData?.faqs && <FAQSection data={faqsData} />}
      </div>

      {/* Footer Top */}
      <div className=" tw-bg-theme lg:tw-py-8 md:tw-py-7 sm:tw-py-6 tw-py-4">
        <Link
          to="/category/Smoothie%20Fastenkuren_1"
          className=" tw-flex tw-items-center tw-justify-center tw-no-underline tw-group"
        >
          <p className="tw-mr-4 tw-text-white 2xl:tw-text-[53px] group-hover:tw-text-slate-50 xl:tw-text-4xl lg:tw-text-3xl  tw-mb-0 tw-font-bold">
            Jetzt deine Smoothie-Kur starten
          </p>
          <img
            src={arrowTheme}
            className="2xl:tw-w-11 2xl:tw-h-11 xl:tw-w-8 xl:tw-h-8 lg:tw-w-7 lg:tw-h-7 sm:tw-w-6 sm:tw-h-6 tw-w-4 tw-h-4"
          />
        </Link>
      </div>
      {/* ENDS Footer TOP */}
      {/* FAQ Section Ends */}
      {/* <RecipeBanner /> */}

      {/* <!-- slider owl-carousel start --> */}

      {/* <!-- slider owl-carousel end --> */}

      {/* <!------image and text section name vision & recipes start------> */}

      {/* <!------image and text section name vision & recipes End------> */}
    </div>
  );
}

// in PHP we can assign values in both way assign by value and assign by reference. assign by value will not update original variable, while assign by refernce will update original variable as well
