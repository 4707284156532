import React, { useLayoutEffect } from "react";
import {
  Outlet,
  Routes,
  Route,
  BrowserRouter,
  Navigate,
} from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import Home from "./pages/Home";
import BoxUi from "./pages/Box/BoxDetail";
import About from "./pages/About/About";
import FAQ from "./pages/FAQ";
import WhyIndivit from "./pages/WhyIndivit/WhyIndivit";
import Sustainability from "./pages/Sustainability/Sustainability";
import Contact from "./pages/Contact/Contact";
import RightRecipes from "./pages/Recipes/RightRecipes";
import RecipeDetail from "./pages/Recipes/RecipeDetail";
import Ingredients from "./pages/Ingredients/Ingredients";
import IngredientDetail from "./pages/Ingredients/IngredientDetail";
import WishList from "./pages/WishList/WishList";
import DiscoverRecipes from "./pages/Recipes/DiscoverRecipes";
import CustomSmoothie from "./pages/Box/CustomSmoothieBox";
import Cart from "./pages/Cart/Cart";
import SmoothieMixer from "./pages/Recipes/SmoothieMixer";
import Login from "./pages/Auth/Login";
import Signup from "./pages/Auth/Signup";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import GetReset from "./pages/Auth/GetReset";
import session from "./services/utils/session";
import { useState, useEffect } from "react";
import NotFound from "./pages/404";
import Profile from "./pages/Auth/Profile";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import Boxes from "./pages/Box/Boxes";
import Checkout from "./pages/Checkout/Index";
import Orders from "./pages/Orders/Orders";
import MyCreations from "./pages/MyCreations/MyCreations";
import Invoice from "./pages/Orders/Invoice";
import OrderDetail from "./pages/Orders/OrderDetail";
import ModalContainer from "./components/Modal/ConfirmWishModal";
import Subscriptions from "./pages/Subscriptions";
import UpdatePassword from "./pages/Auth/UpdatePassword";
import TermsConditions from "./pages/TermsConditions/TermsConditions";
import DataPrivacy from "./pages/DataPrivacy/DataPrivacy";
import Imprint from "./pages/Imprint/Imprint";
import Delivery from "./pages/Delivery/Delivery";
import HPP from "./pages/HPP/HPP";
import Story from "./pages/Story/Story";
import SmoothieFeedback from "./pages/UserFeedback/SmoothieFeedback.jsx";
import ThankYou from "./pages/Thankyou";
import PaymentOptions from "./pages/PaymentOptions/Payment.jsx";
import Analytics from "./components/Analytics.jsx";
import Blogs from "./pages/Blogs/Blogs.jsx";
import BlogDetail from "./pages/Blogs/BlogDetail.jsx";
import Congratulations from "./pages/Congratulation.jsx";
import BoxCategory from "./pages/BoxCategory/Category.jsx";

export default function Routeing() {
  const isAuthenticated = useSelector((state) => state.account.isAuthenticated);

  return (
    <div className="bg-light">
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<p>Home</p>} /> */}
          <Route
            element={
              <div>
                <ToastContainer />
                {/* <ModalContainer /> */}
                <Navbar />
                <Outlet />
                <Footer />
                <Analytics />
              </div>
            }   
          >
            <Route path="/" element={<Home />} />
            <Route path="/boxes" element={<Boxes />} />
            <Route path="/category/:slug" element={<BoxCategory />} />
            <Route path="/b/:id" element={<BoxUi />} />
            <Route path="/about" element={<About />} />
            <Route path="/faqs" element={<FAQ />} />
            <Route path="/whyindivit" element={<WhyIndivit />} />
            <Route path="/sustainability" element={<Sustainability />} />
            <Route path="/imprint" element={<Imprint />} />
            <Route path="/delivery" element={<Delivery />} />
            <Route path="/hpp" element={<HPP />} />
            <Route path="/cancellation_policy" element={<Story />} />
            <Route path="/zahlungsmöglichkeiten" element={<PaymentOptions />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="/privacy-statement" element={<DataPrivacy />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/rightrecipes" element={<RightRecipes />} />
            <Route path="/custombox" element={<CustomSmoothie />} />
            <Route path="/custombox/:id" element={<CustomSmoothie />} />
            <Route path="/custombox/:id/:size" element={<CustomSmoothie />} />
            <Route path="/recipedetail/:id" element={<RecipeDetail />} />
            <Route path="/ingredients" element={<Ingredients />} />
            <Route path="/smoothiemixer" element={<SmoothieMixer />} />
            <Route path="/smoothiemixer/:id" element={<SmoothieMixer />} />
            <Route path="/f/:id" element={<SmoothieFeedback />} />
            <Route path="/smoothie-wissen" element={<Blogs />} />

            <Route path="/smoothie-wissen/:slug" element={<BlogDetail />} />

            {/* <Route
              path="/smoothie_feedback/:id"
              element={<SmoothieFeedback />}
            />
            <Route path="/order_feedback/:id" element={<OrderFeedback />} /> */}

            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/subscriptions" element={<Subscriptions />} />
            <Route path="/thank_you" element={<ThankYou />} />
            <Route path="/congratulations" element={<Congratulations />} />
            {isAuthenticated ? (
              <>
                <Route path="/profile" element={<Profile />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/orders/:id" element={<OrderDetail />} />
                <Route path="/invoice/:id" element={<Invoice />} />
                <Route path="/my-creations" element={<MyCreations />} />
              </>
            ) : (
              <>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route path="/getreset" element={<GetReset />} />
                <Route path="/update_password" element={<UpdatePassword />} />
              </>
            )}
            {/* <Route path="/ingredients/:id" element={<Ingredients />} /> */}
            <Route
              path="/ingredientdetail/:id"
              element={<IngredientDetail />}
            />
            <Route path="/wishlist" element={<WishList />} />
            <Route path="/discoverrecipes" element={<DiscoverRecipes />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
  //    <RouterProvider router={router} />;
}
