import React from "react";

export function CheckIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.422"
      height="11.568"
      viewBox="0 0 15.422 11.568"
      className={className}
    >
      <g id="check" transform="translate(-50.744)">
        <g
          id="Group_1000001281"
          data-name="Group 1000001281"
          transform="translate(50.744)"
        >
          <path
            id="Path_42592"
            data-name="Path 42592"
            d="M56.528,69.767l-3.856-3.855L50.744,67.84l5.784,5.784,9.638-9.639-1.926-1.929Z"
            transform="translate(-50.744 -62.056)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
