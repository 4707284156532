import React, { useLayoutEffect, useState, useRef, useEffect } from "react";
import productImg from "../../assets/img/products-popup.png";
import BoxUiBanner from "../Box/BoxUiBanner";
import {
  BoxCard,
  IngrListforReci,
  RecipeCard,
  SkeltonCard,
} from "../../components/Cards";
import {
  getSmoothies,
  getSmoothieById,
  deleteSmoothie,
} from "../../services/Smoothie";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/common/Loader";
import { baseURL } from "../../services/Adapter/customAxios";
// import RecipeDetailPopup from "../components/RecipeDetailPopup";
import AllNutrientsPopup from "../Ingredients/AllNutrientsPopup";
import TasteSection from "../Ingredients/TasteSection";
import ShareButtons from "../../components/common/ShareButtons";
import { BgSmoothieMixer, Heart } from "../../assets/icon/svgIcons";
import { IsWishlist } from "../../components/common/utils";
import { useSelector } from "react-redux";

import { toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ConfirmModal from "../../components/Modal/ConfirmModal";
import useAddWishlist from "../../hooks/useAddWishlist";
import { addWishlistSmoothie } from "../../services/Wishlist";
import { TextSkelton } from "../../components/common/Common";
import ModalContainer from "../../components/Modal/ModalContainer";
import ConfirmWishModal from "../../components/Modal/ConfirmWishModal";
import useCheckStock from "../../hooks/useCheckStock";
import useIngredientStatus from "../../hooks/useIngredientStatus";
import { getBoxBenefits } from "../../services/SmoothieBox";
import StarRating from "../../components/common/StarRating";
import { Helmet } from "react-helmet";
import { formatToGerman1 } from "../../services/utils/germanFormat";

export default function RecipeDetail() {
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(false);
  const { isLoading, isDone, addWishlist } =
    useAddWishlist(addWishlistSmoothie);
  const { isOutofStock, checkStock } = useCheckStock();

  const [modalVisible, setModalVisible] = useState(false);
  let queryClient = useQueryClient();
  const navigate = useNavigate();
  const closeRef = useRef();
  let params = useParams();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [params]);
  const {
    isLoading: smoothieLoading,
    error: smoothieError,
    data: smoothieData,
  } = useQuery({
    queryKey: ["smoothieListing"],
    queryFn: getSmoothies,
  });
  const smoothiesListing = smoothieData?.data?.smoothies;
  const {
    isLoading: smoothieByIdLoading,
    error: smoothieByIdError,
    data: smoothieByIdData,
  } = useQuery({
    queryKey: ["smoothieById", params?.id],
    queryFn: () => getSmoothieById(params?.id),
  });
  useEffect(() => {
    if (smoothieByIdData?.response?.status == 404) {
      toast.error("Keine Daten gefunden");
      navigate("/rightrecipes");
    }
  }, [smoothieByIdData]);

  let data = smoothieByIdData?.data?.smoothie;
  let relative_boxes = smoothieByIdData?.data?.smoothie_box;
  const wishlist = useSelector((state) => state?.wishlist);

  const {
    isLoading: benefitsLoading,
    error: benefitsError,
    data: benefitsData,
  } = useQuery({
    queryKey: ["boxBenefits"],
    queryFn: getBoxBenefits,
  });

  const handleDelete = (id) => {
    setLoading(true);
    deleteSmoothie(id)
      .then((res) => {
        // console.log(res);
        if (res?.status == 200) {
          closeRef.current.click();
          toast.success("Dein Smoothie wurde gelöscht");
          queryClient.invalidateQueries([
            "customSmoothieListing",
            "smoothieListing",
            "limitedSmoothieListing",
            "smoothieById",
          ]);
          navigate("/rightrecipes");
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.data);
      });
  };
  const handleWishlist = (id) => {
    IsWishlist(0, data?.id, wishlist)
      ? setModalVisible(true)
      : addWishlist({ smoothie_id: id });
  };
  useEffect(() => {
    if (isDone) {
      setModalVisible(false);
    }
  }, [isDone]);
  let ingredients_suggestion = data?.smoothie_ingredient.map(
    (e) => e.ingredient
  );
  // useEffect(() => {
  //   console.log("Is Out Of Stock -> ", isOutofStock);
  // }, [isOutofStock]);
  useEffect(() => {
    // check
    checkStock(ingredients_suggestion);
  }, [data]);
  // console.log("Data --->>", data);

  const commonImg = useSelector((state) => state.settings?.smoothieImg);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
      </Helmet>
      {data && (
        <Helmet>
          {data?.name && <title> {`Indivit | ${data?.name}`} </title>}{" "}
          {data?.headline && (
            <meta name="description" content={data?.headline} />
          )}
        </Helmet>
      )}
      <div>
        <AllNutrientsPopup data={data?.smoothie_nutrients} />
        <ModalContainer
          isOpen={modalVisible}
          closeModal={() => setModalVisible(false)}
        >
          <ConfirmWishModal
            setModalVisible={setModalVisible}
            fun={() => addWishlist({ smoothie_id: data?.unique_id })}
            innerHtml="Du entfernst das Element gerade von deinem Merkzettel"
            okLabel="Klingt gut"
          />
        </ModalContainer>
        {(loading || smoothieLoading || smoothieByIdLoading) && <Loader />}
        <section id="flx-hero-rdetailed">
          <div className="container">
            <div className="">
              <nav aria-label="breadcrumb" className="px-0">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/rightrecipes">Smoothie</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {smoothieByIdLoading ? <TextSkelton /> : data?.name}
                  </li>
                </ol>
              </nav>
            </div>

            <div className="row d-flex pt-0 pt-md-5">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="text-center  position-relative min-h-blender">
                  <div className="w-100 position-absolute">
                    <BgSmoothieMixer />
                    <div className="z-index-10 position-absolute top-0 start-0 end-0 ">
                      <img
                        src={
                          data?.smoothie_picture?.picture
                            ? baseURL +
                              "smoothie/" +
                              data?.smoothie_picture?.picture
                            : commonImg
                        }
                        className="img-fluid flx-rdetailed-animation max-h- w-100 !tw-duration-500"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flx-rdetailed-leave">
                <div className="text-left">
                  <div className="d-flex align-items-center ">
                    <span className="fw-bold fs-2">
                      {smoothieByIdLoading ? <TextSkelton /> : data?.name}
                    </span>
                    {data?.created_by == 1 && (
                      <span
                        className={`badge rounded-pill   text-uppercase bg-info ms-2`}
                      >
                        Customized
                      </span>
                    )}
                  </div>
                  {data?.counts > 0 && (
                    <h6 className="d-flex">
                      <StarRating value={data?.ratings} />
                      &nbsp;
                      {formatToGerman1(data?.ratings)} / 5,0&nbsp;(
                      {data?.counts}&nbsp;Bewertungen)
                    </h6>
                  )}
                  {/* Rating Here END */}
                  {isOutofStock && (
                    <span
                      className={`badge rounded-pill text-uppercase bg-danger mb-2`}
                    >
                      derzeit nicht verfügbar
                    </span>
                  )}
                  <p>{data?.headline}</p>
                  <button
                    className="btn btn-outline-success shadow-none rounded-pill"
                    onClick={() => handleWishlist(data?.unique_id)}
                  >
                    {/* Type  0 => Recipe, 1 => Box , 2=> Ingredient */}
                    {isLoading ? (
                      <span
                        class="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <span className="me-2">
                        <Heart filled={IsWishlist(0, data?.id, wishlist)} />
                      </span>
                    )}
                    {IsWishlist(0, data?.id, wishlist)
                      ? "Zur Wunschliste hinzugefügt"
                      : "Auf den Merkzettel"}
                  </button>
                  <div className="d-flex">
                    <h5 className="pt-4 hsn-smoothies">Zutaten</h5>

                    <button
                      type="button"
                      className="btn btn-link btn-solid-success-color mt-3 fs-14 ms-md-3 ms-0"
                      data-bs-toggle="modal"
                      data-bs-target="#nutrientsModal"
                      data-bs-whatever="@getbootstrap"
                    >
                      Durchschnittliche Nährwerte
                    </button>
                  </div>
                  <div className="custom-scroll max-h-410 overflow-auto">
                    {smoothieByIdLoading ? (
                      <>
                        <TextSkelton /> <br />
                        <TextSkelton /> <br />
                        <TextSkelton />
                      </>
                    ) : (
                      data?.smoothie_ingredient
                        ?.sort(
                          (a, b) =>
                            parseFloat(b.value_in_percentage) -
                            parseFloat(a.value_in_percentage)
                        )
                        ?.map((ing, index) => {
                          return (
                            <div key={index}>
                              <IngrListforReci
                                data={ing}
                                suggestions={ingredients_suggestion}
                              />
                            </div>
                          );
                        })
                    )}
                  </div>
                  <div className="mt-3">
                    <Link
                      to={`/smoothiemixer/${data?.unique_id}`}
                      className="btn btn-outline-success"
                    >
                      Customize
                    </Link>
                    {data?.created_by == "1" && (
                      <ConfirmModal
                        action={() => handleDelete(data?.id)}
                        closeRef={closeRef}
                        isLoading={loading}
                        innerHtml="Bist du dir sicher, dass du dein Rezept löschen möchtest?"
                      />
                    )}
                  </div>
                  <div className="mt-3">
                    {!isOutofStock && (
                      <Link
                        to={`/custombox`}
                        state={{ add_me: data?.unique_id }}
                        className="btn !tw-bg-theme !tw-text-white !tw-font-bold tw-decoration-transparent tw-w-fit !tw-py-[10px] !tw-px-8"
                      >
                        <span>Pack mich in eine Box!</span>
                      </Link>
                    )}
                  </div>
                  {data?.created_by == "0" && <ShareButtons />}
                </div>
              </div>
              {data?.created_by != 1 && (
                <div className="col-12 text-center my-4">
                  <h5> {data?.headline} </h5>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.smoothie_recipe_text?.recipe_text,
                    }}
                  ></div>
                </div>
              )}
            </div>
          </div>
        </section>
        <BoxUiBanner
          // data={benefitsData?.data?.data}
          benefits={
            benefitsData?.data?.data?.benefits &&
            JSON.parse(benefitsData?.data?.data?.benefits)
          }
          isHeading={false}
        />
        {/* <!-- progressbar section --> */}
        <section id="flx-rdetailed-bar">
          <div className="container">
            <div className="row d-flex pt-0 pt-md-5">
              <div className="col-12  col-md-6 ">
                <h3>Wie schmeckt der Smoothie?</h3>
                <TasteSection
                  data={data?.smoothie_recipe_taste}
                  suggest="no"
                  basiColor={"green"}
                />
              </div>
              <div className="col-12  col-md-5 offset-lg-1 my-5 my-md-0">
                <div className="text-left ">
                  <h2 className="flx-rdetailed-leaves">
                    Du willst es ganz &nbsp;
                    <span className="hsn-smoothies">genau</span>&nbsp; wissen?
                  </h2>
                  <p>
                    Hier kannst du alles über die Inhaltstoffe dieses Smoothies
                    herausfinden
                  </p>
                  <button
                    type="button"
                    className="btn btn-primary btn-outline-success mt-3"
                    data-bs-toggle="modal"
                    data-bs-target="#nutrientsModal"
                    data-bs-whatever="@getbootstrap"
                  >
                    Mehr anzeigen
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- progressbar section --> */}
        {/* <!------Our boxes start------> */}
        <section id="flx-discover-recipes" className="pb-0">
          <div className="container">
            <h3 className="py-5" data-aos="fade-up" data-aos-duration="1000">
              Boxen, die diesen Smoothie enthalten...
            </h3>
            <div className="row">
              {smoothieByIdLoading || relative_boxes?.length == 0
                ? Array.from(Array(3))?.map((box, index) => {
                    return (
                      <div
                        key={index}
                        className="col-12 col-md-4  hsn-box-bg"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                      >
                        <SkeltonCard />
                      </div>
                    );
                  })
                : relative_boxes?.map((box, index) => {
                    return (
                      <div key={index} className="col-12 col-md-4  p-3">
                        <div
                          className="  hsn-box-bg"
                          data-aos="fade-up"
                          data-aos-duration="1000"
                        >
                          <BoxCard data={box} />
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </section>
        {/* <!------Our boxes end------> */}
        <section id="flx-discover-recipes">
          <div className="container">
            <h3 className="py-5" data-aos="fade-up" data-aos-duration="1000">
              Weitere Smoothies, die dir gefallen könnten...
            </h3>
            <div className="row">
              {smoothiesListing?.slice(0, 3)?.map((smooth, index) => {
                return (
                  <div
                    className="col-12 col-md-4 my-3 my-md-0"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <RecipeCard
                      isButton={true}
                      data={smooth}
                      actionTitle="Mehr anzeigen"
                      action={`/recipedetail/${smooth?.unique_id}`}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
