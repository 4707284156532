import { GET } from "./Adapter/base";

export const getHomeSlider = () => {
  return GET(`get_home_slider`);
};

export const getHomePage = () => {
  return GET(`homePage`);
};
export const getBoxesByCat = (id) => {
  return GET(`smoothieBoxByCategory/${id}`);
};
