import { GET } from "./Adapter/base";

export const getAboutUsPage = () => {
  return GET(`about_us`);
};
export const getWhyIndivitPage = () => {
  return GET(`why_indivit`);
};
export const getTermsPage = () => {
  return GET(`terms_condition`);
};
export const getPrivacyPage = () => {
  return GET(`data_privacy`);
};
export const getDeliveryPage = () => {
  return GET(`delivery`);
};
export const getImprintPage = () => {
  return GET(`imprint`);
};
export const getStoryPage = () => {
  return GET(`story_page`);
};
export const getHppPage = () => {
  return GET(`hpp_procedure`);
};
export const getPayPolicyPage = () => {
  return GET(`payment_policy`);
};
export const getFaqPage = () => {
  return GET(`faqs`);
};
